body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    
}
a {
    text-decoration: none;
    color: #000;
}

.hover-container .hover  {
    display: none;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    transition: all 0.5s;
}
.hover-container:hover .hover {
    display: block;
    opacity: 1;
}
.white {
    /* opacity: 0; */
}