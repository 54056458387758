.scroller-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .scroller-track {
    display: flex;
    animation: scroll-left 20s linear infinite;
    gap: 30px;
    /* animation-play-state: paused; */
  }
  
  .scroller-track .embla {
    height: 250px;
    flex-shrink: 0;
  }

  .scroller-track img {
    height: 250px;
    flex-shrink: 0;
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

.scroller-container:hover .scroller-track {
    animation-play-state: paused;
  }